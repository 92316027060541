<template>
  <div class="d-flex flex-column flex-grow-1">
    <document-toolbar
      :tab="tab"
      :files.sync="document.attachments"
      :revert="refreshDocument"
      :save="saveDocument"
      :readonly-file-manager="!document.canUpdate"
      :hasComment="!!(document.description || '').length"
      show-file-manager
      comment
      v-on="$listeners"
      :links.sync="document.links"
      :show-link-manager="!documentIsNew"
      :labels.sync="document.labels"
      :show-labels="!documentIsNew"
      @close-pop-up="refreshCache()"
      @update-links="refreshCache()"
    >
      <collaboration-status
        class="ml-3"
        :status="statusWithWorkflow"
      >
        <template v-slot:status>
          <div
            class="mr-1"
            v-if="statusWithWorkflow.workflow.name"
          >
            <v-icon
              small
              class="mr-1"
            >{{$icon('i.Workflow')}}</v-icon>
            <span>{{statusWithWorkflow.workflow.name}}</span>
          </div>
          <span
            v-if="documentIsNew || initialWorkflowId !== document.workflowId || !document.canUpdate"
            class="ml-3 secondary--text font-weight-bold"
          >
            <document-name-ref
              v-if="document.statusId"
              :id="document.statusId"
              :cache-type="cacheTypeStatus"
            />
          </span>
          <v-select
            v-else
            class="mx-1"
            solo
            hide-details
            :items="availableStatuses"
            item-text="name"
            item-value="id"
            v-model="statusId"
            :menu-props="{closeOnContentClick: true, bottom: true, offsetY: true}"
            :background-color="statusWithWorkflow.isBeyondTarget ? 'red': null"
          />
        </template>
      </collaboration-status>
      <v-col
        cols="auto"
        v-if="!document.isClosed && needValidation && this.currentContributor && this.currentContributor.isRequired && !this.currentContributor.actionIsValidated"
      >
        <v-btn
          small
          color="accent"
          @click.stop="confirmValidate = true"
        >{{$t('t.Validate')}}
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        class="ml-3"
      >
        <account-ref
          v-if="document.accountId !== $route.params.id"
          :icon-props="{small: true}"
          :id="document.accountId"
          class="clickable secondary--text"
          @click.native.stop="$router.push('/accounts/' + document.accountId).catch(() => {})"
        />
      </v-col>
    </document-toolbar>
    <document-in-tab>
      <v-form
        @input="validateForm"
        ref="form"
        :disabled="!document.canUpdate || statusWillCloseCollaboration"
      >
        <comment
          :text.sync="document.description"
          :disabled="!document.canUpdate || statusWillCloseCollaboration"
          :showComment="tab.showComment"
          :counter="250"
          :rules="rules"
          class="mb-5"
        />
        <v-row>
          <v-col>
            <v-card>
              <v-card-title>
                <div class="minimum-width text-h5">
                  {{$t('t.CollaborationTaskAmount')}}
                </div>
              </v-card-title>
              <v-card-text>
                <collaboration-tasks-amount
                  :collaborationTask.sync="document"
                  :collaborationTaskIsNew="documentIsNew"
                  @update:collaboration-task="calculateWorkflow"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-row
              class="flex-column mt-n1"
              dense
            >
              <v-col>
                <v-card>
                  <v-card-title>
                    {{$t('t.Informations')}}
                  </v-card-title>
                  <v-card-text>
                    <collaboration-tasks-infos
                      :collaborationTask.sync="document"
                      :statusWillCloseCollaboration="statusWillCloseCollaboration"
                      :collaborationTaskIsNew="documentIsNew"
                      @update:type="calculateWorkflow"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-card>
              <v-card-title>
                {{$t('Participants')}}
              </v-card-title>
              <v-card-text>
                <collaboration-tasks-contributors
                  :collaboration-task.sync="document"
                  :status-will-close-collaboration="statusWillCloseCollaboration"
                  @update:collaboration-task="validateForm"
                  ref="contributors"
                />
                <collaboration-tasks-notification
                  class="mt-6"
                  :collaboration-task.sync="document"
                  :status-will-close-collaboration="statusWillCloseCollaboration"
                  :can-update="this.document.canUpdate"
                  ref="confirmations"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </document-in-tab>
    <v-dialog
      v-model="confirmValidate"
      max-width=290
      @click:outside="confirmValidate = false"
    >
      <v-card>
        <v-card-title>{{$t('t.PromptConfirmation')}}</v-card-title>
        <v-card-text>{{$t('t.ValidateConfirmation')}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="validate()">{{$t('t.Yes')}}</v-btn>
          <v-btn
            color="primary"
            @click="confirmValidate = false"
          >{{$t('t.No')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import DocumentEditor from '@/mixins/document-editor'
import { refreshCache } from '@/wasm/pkg'

export default {
  mixins: [DocumentEditor],
  components: {
    AccountRef: () => import('@/components/documents-ref/account-ref'),
    CollaborationTasksAmount: () => import('./collaboration-tasks-amount'),
    CollaborationTasksInfos: () => import('./collaboration-tasks-infos'),
    CollaborationTasksContributors: () => import('./collaboration-tasks-contributors'),
    CollaborationTasksNotification: () => import('./collaboration-tasks-notification'),
    Comment: () => import('@/components/comment'),
    DocumentInTab: () => import('@/components/document-in-tab'),
    DocumentNameRef: () => import('@/components/documents-ref/document-name-ref'),
    DocumentToolbar: () => import('@/components/document-toolbar'),
    CollaborationStatus: () => import('@/components/dispute-collaboration-status')
  },
  created () {
    Object.assign(this.tab, { cancel: this.cancel })
  },
  data () {
    return {
      cacheTypeStatus: DocumentEditor.CacheType.StatusDisputeRef,
      confirmValidate: false,
      rules: [value => (value?.length ?? 0) <= 250],
      statusWillCloseCollaboration: false,
      status: {
        canApprove: true,
        canReject: true,
        canCancel: true,
        statuses: [],
        validationRequired: false
      },
      dialog: false,
      required: [
        v => !!v || this.$t('t.IsRequired')
      ],
      workflowName: null
    }
  },
  computed: {
    cacheType () {
      return DocumentEditor.CacheType.CollaborationDetail
    },
    id () {
      return this.tab.documentId
    },
    initialWorkflowId () {
      return this.cache?.doc?.workflowId
    },
    statusId: {
      get () {
        return this.document.statusId
      },
      set (statusId) {
        this.document = Object.assign({}, this.document, { statusId })
        this.updateStatuses()
      }
    },
    statusWithWorkflow () {
      return {
        id: this.statusId,
        targetDate: this.document.targetDate,
        isBeyondTarget: this.document.isBeyondTarget,
        statusChangedAt: this.document.statusChangedAt,
        workflow: {
          name: this.workflowName
        }
      }
    },
    currentContributor () {
      if (this.document.participantList?.length > 0) {
        return [this.document.owner, ...this.document.participantList].find(u => u?.id === this.$store.getters.currentUser.id)
      }
      if (this.document.owner?.id === this.$store.getters.currentUser.id) {
        return this.document.owner
      }
      return null
    },
    closeStatuses () {
      const statuses = []
      if ((this.status.canApprove && this.canApprove) || this.cache?.doc?.statusId === this.document.approvedStatus) {
        statuses.push({
          id: this.document.approvedStatus,
          name: this.$t('t.Approve'),
          isClosedStatus: true,
          disabled: !this.tab.isPristine && this.cache?.doc?.statusId !== this.document.approvedStatus
        })
      }
      if ((this.status.canReject && this.canReject) || this.cache?.doc?.statusId === this.document.rejectedStatus) {
        statuses.push({
          id: this.document.rejectedStatus,
          name: this.$t('t.Reject'),
          isClosedStatus: true,
          disabled: !this.tab.isPristine && this.cache?.doc?.statusId !== this.document.rejectedStatus
        })
      }
      if ((this.status.canCancel && this.canCancel) || this.cache?.doc?.statusId === this.document.canceledStatus) {
        statuses.push({
          id: this.document.canceledStatus,
          name: this.$t('t.Delete'),
          isClosedStatus: true,
          disabled: !this.tab.isPristine && this.cache?.doc?.statusId !== this.document.canceledStatus
        })
      }
      return statuses
    },
    availableStatuses () {
      const statuses = this.status.statuses.slice().filter(s => !this.status.validationRequired || this.document.isValidated || s.id === this.document.statusId)
      if (this.closeStatuses.length) {
        statuses.push({ divider: true }, ...this.closeStatuses)
      }
      return statuses
    },
    canCancel () {
      return !this.documentIsNew && this.document.createBy === this.$store.getters.currentUser.id
    },
    canApprove () {
      return !this.documentIsNew && !this.needValidation && this.document.canUpdate
    },
    canReject () {
      return this.canApprove
    },
    needValidation () {
      return !this.document.isValidated && (!this.document.workflowId || this.status.validationRequired)
    }
  },
  methods: {
    async refreshDocumentHook () {
      if (this.documentIsNew) {
        this.document = Object.assign({}, this.document, {
          accountId: this.$route.params.id,
          amount: 0,
          attachments: [],
          canUpdate: true,
          collaborationTaskTypeId: undefined,
          confirmations: [],
          createBy: this.$store.getters.currentUser.id,
          currency: this.$store.getters.currentUser.currency,
          description: null,
          id: this.tab.documentId,
          isBeyondTarget: false,
          isClosed: false,
          groupContributorId: null,
          nbDaysBeforeNotification: 1,
          owner: { id: this.$store.getters.currentUser.id },
          participantList: [],
          reminders: [],
          statusId: undefined,
          targetDate: undefined,
          workflowId: null,
          isValidated: false
        })
      } else {
        // Prevent doing anything if the cache isn't loaded
        if (!this.document?.id) { return }
        this.$emit('document-title-change', { tab: this.tab, value: `${this.tab.typeName} ${this.document.number}` })
        // await this.$http().patch('/collaborationTaskParticipants/' + this.document.id)

        if ((this.document.description || '').length) {
          this.$emit('document-comment', { tab: this.tab, value: true })
        }
        if (!this.document.isClosed) {
          await this.calculateWorkflow()
        }
        if (this.document.description?.length) {
          this.$emit('document-comment', { tab: this.tab, value: true })
        }
      }

      this.validateForm()
    },
    async calculateWorkflow () {
      if (!this.document.id) { return }
      if (this.calculatingWorkflowInProgress) {
        return new Promise(resolve => {
          const interval = setInterval(() => {
            if (!this.calculatingWorkflowInProgress) {
              clearInterval(interval)
              resolve()
            }
          }, 100)
        })
      }
      this.calculatingWorkflowInProgress = true
      try {
        const workflow = (await this.$http().post('/core/v6/collaboration-task/compute-workflow', await this.collaborationTaskToJSON())).data
        this.status = Object.assign({}, this.status, workflow.computeStatuses)
        this.status.statuses.sort((a, b) => a.sequence > b.sequence)
        this.workflowName = this.document.workflowId ? workflow.name : null

        if (this.document.workflowId !== workflow.id || !this.document.statusId) {
          this.document = Object.assign({}, this.document, {
            workflowId: workflow.id
          })
          this.workflowName = this.document.workflowId ? workflow.name : null

          const newStatus = this.status.statuses[0]
          if (!newStatus) return
          this.document = Object.assign({}, this.document, { statusId: newStatus.id, groupContributorId: newStatus.groupContributorId })
          this.$refs?.contributors?.update()
          this.statusWillCloseCollaboration = newStatus.isClosedStatus
        }
      } catch (e) {
        // If the collaboration task is not completely set it may end up with a status 400 that should be ignored
        if (e.response?.status !== 400) {
          throw e
        }
      } finally {
        this.calculatingWorkflowInProgress = false
      }
    },
    updateStatuses () {
      const currentStatus = [...this.status.statuses, ...this.closeStatuses].find(s => s.id === this.document.statusId) || this.status.statuses[0]
      if (!currentStatus) return
      this.document = Object.assign({}, this.document, { statusId: currentStatus.id, groupContributorId: currentStatus.groupContributorId })
      this.statusWillCloseCollaboration = currentStatus.isClosedStatus
      if (this.document.workflowId) {
        this.$refs?.contributors?.update()
      }
    },
    validateForm () {
      this.$waitFor(() => this.$refs.form)
        .then(form => this.$emit('document-can-save', { tab: this.tab, value: Boolean(this.document.canUpdate && form.validate() && this.document.owner?.id) }))
        .catch(e => {
          // Do not throw if the cache is falsy, that mean the user as already close the document
          if (this.cache) {
            throw e
          }
        })
    },
    collaborationTaskToJSON () {
      const formatedParticipants = [this.document.owner, ...this.document.participantList].map((p, index) => {
        if (p) {
          return {
            id: this.uuid(),
            userId: p.userRole ? null : p.id,
            collaborationTaskId: this.document.id,
            isOwner: index === 0,
            indexOrder: index === 0 ? undefined : p.indexOrder,
            userRole: p.userRole,
            isRequired: p.isRequired
          }
        } else return null
      })

      const formatedAttachments = this.document.attachments?.map(a => a.id)

      return {
        id: this.document.id,
        accountId: this.document.accountId,
        attachments: formatedAttachments,
        collaborationTaskTypeId: this.document.collaborationTaskTypeId,
        confirmations: this.document.confirmations.map(c => ({ userId: c.userId || c.id })),
        reminders: this.document.reminders.map(c => ({ userId: c.userId || c.id })),
        targetDate: this.document.targetDate,
        currency: this.document.currency,
        description: this.document.description,
        amount: this.document.amount,
        participants: formatedParticipants,
        statusId: this.document.statusId,
        groupContributorId: this.document.groupContributorId ?? null,
        nbDaysBeforeNotification: this.document.reminders.length ? this.document.nbDaysBeforeNotification : 0
      }
    },
    async save () {
      if (this.documentIsNew) {
        return this.$http().post('/core/v6/collaboration-task', await this.collaborationTaskToJSON())
      } else {
        return this.$http().put(`/core/v6/collaboration-task/${this.document.id}`, await this.collaborationTaskToJSON())
      }
    },
    async validate () {
      await this.$http().put('/core/v6/collaboration-task/' + this.document.id + '/validate-collaboration', {})
      this.confirmValidate = false
      refreshCache()
    },
    cancel () {
      this.$removeFromKeepAliveCache()
      this.$destroy()
    }
  },
  props: {
    tab: Object
  }
}
</script>
